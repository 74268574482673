.modal-overlay-custom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: 1000;
  padding: 20px 0;
}

.modal-content-custom {
  background: #fff;
  width: 90%;
  padding: 20px 0;
  border-radius: 8px;
  position: relative;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  height: 80%;
  max-height: 100vh; 
  position: relative;
  overflow-y: auto;
}

.modal-overlay-custom.open {
  opacity: 1;
  visibility: visible;
}

.modal-content-custom.open {
  opacity: 1;
  transform: translateY(0);
}