.rank{
    font: 14px;
    font-weight: 500;
}
.c1{
    
}
.sl .css-1s2u09g-control {
    height: 30px !important;
}
.css-1pahdxg-control{
    border-color:hsl(0, 0%, 80%) !important ;
    outline: none !important;
    height: 47px !important;
    box-shadow: none !important;
    background-color: transparent !important;
    transition: none !important;
}