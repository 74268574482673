.notification_main{
    padding: 0px 36px 30px 36px;
    margin-top: -15px !important;
}
.notification-box{
    cursor: pointer;
}
 .cross:hover{
    background-color: black;
    transition: .4s all ease;
}
.box-div{
    padding: 5px;
    border-radius: 50%;
    background-color: #FFEBEE;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cross{
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background:#f8bbc2 ;
    margin: 0 8px;
}
@media only screen and (max-width: 820px) {
    .notification_main{
        /* padding: 0px ; */
        padding: 0px 25px 30px 25px;
    }
  }
@media only screen and (max-width: 768px) {
    .notification_main{
        /* padding: 0px 22px; */

        padding: 0px 22px 30px 22px;
    }
  }
@media only screen and (max-width: 576px) {
    .notification_main{
        padding: 0px 15px 30px 15px;

    }
  }
@media only screen and (max-width: 450px) {
    .notification_main{
        padding: 0px 8px 20px 8px;

    }
  }