body {
  --hover_custom_inp: rgb(164, 200, 248);
}

.custom__input__container {
  position: relative;
  width: 100%;
  min-height: 48px;
  padding: 9px;
  background: #fafafa;
  border: 1.5px solid #e0e0e0;
  border-radius: 8px;
}

.custom__input {
  background-color: var(--gray_5);
  width: 100%;
  height: 100%;
  border: 1px solid #dfdede;
  border-radius: 8px;
  cursor: pointer;
  min-height: 48px;
  opacity: 0;
  position: absolute;
  top: 0;
}

.attr_custom__input__btn {
  position: absolute;
  top: 0;
  right: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 57px;
  background: #f2f2f2;
  height: 100%;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 7px;
}

.badge_custom_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 12px;
}

.badge_custom {
  background: #e9e9ee;
  width: max-content;
  padding: 5px;
  border-radius: 2px;
  color: black;
  z-index: 10;
  position: relative;
  height: 35px;
  margin: 5px 0;
}
.badge_custom_for_p {
  width: max-content;
  padding: 5px;
  border-radius: 2px;
  color: black;
  z-index: 10;
  position: relative;
  height: 35px;
  margin: 5px 0;
}

.badge_cross {
  cursor: pointer;
}
.badge_cross:hover {
  color: rgb(214, 61, 61);
}
.badge_items_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 2px;
  background: #f2f2f2;
  border-radius: 4px;
}

.multiple_input {
  background: #fafafa;
  border: 1.5px solid #e0e0e0;
  border-radius: 8px;
  min-height: 48px;
  width: 100%;
}

.multiple_input:focus {
  outline: none;
  border: 1.5px solid #4f4f4f;
  border-radius: 8px;
}
.focus_custom__input__container {
  border: 1.5px solid #4f4f4f;
  border-radius: 8px;
  outline: none;
}

.blink_box h2::after {
  content: "";
  width: 2px;
  height: 30px;
  background: #000000;
  display: inline-block;
}

.blink_box h2 {
  display: flex;
  align-items: center;
  gap: 2px;
}
.blink_box {
  padding: 5px 0;
  height: 35px;
  margin: 2px 0;
}

.blink_box h2::after {
  /*code commented for brevity*/
  animation: cursor-blink 1.5s steps(2) infinite;
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}


svg{
  width: 18px;
  height: 18px;
}
.permisshion{
  display: flex;
  width: 80vw;
  height: 70vh;
  justify-content: center;
  align-items: center;
  color: #000000;
}
