.header_container {
  position: fixed;
  left: 0;
  height: 80px;
  width: 100vw !important;
  z-index: 1000;
  top: 0;
  background: var(--dark);
  overflow: hidden;
}

.header-main {
  padding: 0px 20px;
}
.header-main .bars {
  margin-left: 47px;
}
.header-main .bars2 {
  margin-left: 25px;
}

.header-main .search {
  margin-left: 80px;
  border-radius: 4px;
  padding: 14px 27px;
}
.search input {
  width: 260px;
}

.search-bar {
  display: flex;
  flex: 2 1;
  align-items: center;
  height: 48px;
  padding: 0 18px;
  background: #ffffff;
  border-radius: 4px;
  width: 398px;
}
.search_bar_small {
  display: none;
}
.search-bar input {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  padding-left: 20px;
}
.navLine{
  margin: 0 20px;
}
.navProfile{
  margin: 0 10px;
  width: 50px;
  height: 50px;

}
.logo-m{
  margin-left: 13px;
}


@media only screen and (max-width: 412px) {
  .header-main {
    padding: 0px 5px;
  }
  .header-main .bars {
    margin-left: 10px;
  }
}
@media only screen and (max-width: 820px) {
  .logo-m{
    margin-left: 6px;
  }
  .header-main {
    padding: 0px 20px;
  }
  .header-main .bars {
    margin-left: 30px;
  }
  .header-main .search {
    margin-left: 20px;
    border-radius: 4px;
    padding: 10px 16px;
  }
  .search input {
    width: 100%;
  }
  .navProfile{
    margin: 0 0px;
  
  }
  .navLine{
    display: none;
  }
  .navNotification{
    margin: 0 20px;

  }
}
@media only screen and (max-width: 768px){
  .logo-m{
    margin-left: -8px;
  }
}
@media only screen and (max-width: 600px) {
  .bars ,.bars2{
    display: none;
  }
}

@media only screen and (max-width: 1084px) {
  .search-bar {
    width: 31vw;
  }
}
@media only screen and (max-width: 760px) {
  .name_nav {
    display: none;
  }
}
@media only screen and (max-width: 586px) {
  .search-bar {
    display: none;
  }
  .search_bar_small {
    display: block;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
  }

  .bars,.bars2{
    display: none;
  }

  .navLine{
    display: none;
  }
  .navNotification{
    margin: 0 10px;
    width: 30px;

  }
  .navProfile {
    margin: 0 0px;
    width: 40px;
  }
  .search_bar_small{
    width: 40px;
  }
}
@media only screen and (max-width: 450px) {
  .bars,.bars2{
    display: none;
  }
}
/* @media only screen and (max-width: 360px) {
  .bars{
    display: none;
  }
} */
.user-box-parent{
  position: relative;
  cursor: pointer;
}
.user-box-child{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2000;
  top: 0;
  left: 0;
  cursor: pointer;
}