/* Loader container to center the loader */
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh; /* Full viewport height */
  }
  
  /* Circle loader container */
  .circle-loader {
    position: relative;
    width: 100px;
    height: 100px;
  }
  
  /* Loader border animation */
  .loader-border {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 6px solid #f3f3f3; /* Light grey border */
    border-top: 8px solid #3498db; /* Blue border for animation */
    animation: spin 2s linear infinite; /* Spin animation */
  }
  
  /* Image styling */
  .loader-image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px; /* Adjusted size */
    height: 80px; /* Adjusted size */
    transform: translate(-50%, -50%); /* Center the image */
    border-radius: 50%; /* Optional: make the image circular */
    z-index: 10; /* Ensure the image is above the loader */
    object-fit: cover;
  }
  
  /* Keyframes for the spin animation */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  