.stat-box-main {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 0px 30px -15px;
}
.mt-50 {
  margin-top: 82px !important;
}
input::placeholder {
  font-weight: normal !important;
  padding: 0px 0px 0px 8px !important;
}
input {
  font-weight: normal;
}

.state-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 125px !important;
  border-radius: 4px;
  padding: 0px 35px;
  cursor: pointer;
  width: 300px;
}
.state-main2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 125px !important;
  border-radius: 4px;
  padding: 0px 25px;
  cursor: pointer;
}
.state-img-box {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f5f9;
}
.state-head {
  font-weight: 700;
  font-size: 28px;
  margin: -8px 0px;
}
.mar-box {
  /* margin-right: 30px !important; */
}
.only-margin {
  margin-right: 60px !important;
  margin-left: 23px !important;
}
.invoice-main {
  background-color: white;
  border-radius: 4px;
  margin-left: 10px !important;
  margin-right: 60px !important;
  padding: 20px 25px;
}
.head-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select {
  padding: 6px 8px;
  border-radius: 6px;
  background-color: #f2f5f9;
  cursor: pointer;
}
.select img {
  width: 16.67px;
  height: 16.67px;
}
.select span {
  color: #533e85;
  font-size: 14px;
  font-weight: 500;
}
.select .blue-arrow {
  width: 11.67px;
  height: 8px;
  transition: .5s all ease;
  rotate: 180deg;
}
.select .blue-arrow1 {
  width: 11.67px;
  height: 8px;
  transition: .5s all ease;
  /* rotate: -180deg; */
}
.filter-select select{
    word-wrap: normal !important;
    border-radius: 4px !important;
    padding: 5px !important;
    color: black !important;
    --bg-color: #d7bcbc !important;
}
.filter-select select:checked{
  border: 1px solid #533e85 !important;
}

/* .invoice-table-body {
  height: 300px !important;
  overflow-y: scroll;
} */
.dash-table {
  height: 300px !important;
  overflow-y: scroll !important;
}
@media only screen and (max-width: 820px) {
  .invoice-main {
    margin-right: 60px !important;
    padding: 20px 20px;
  }
  .only-margin {
    margin-right: 30px !important;
  }
  .state-main {
    padding: 0px 30px;
  }
}
@media only screen and (max-width: 768px) {
  .invoice-main {
    margin-right: 30px !important;
    padding: 20px 20px;
  }
  .only-margin {
    margin-right: 50px !important;
  }
  .state-main {
    padding: 0px 28px;
  }
}

@media only screen and (max-width: 412px) {
  .invoice-main {
    margin-right: 30px !important;
  }
  .only-margin {
    margin-right: 30px !important;
  }
  .state-main {
    padding: 0px 20px;
  }
}

@media only screen and (max-width: 414px) {
  .invoice-main {
    margin-right: 13px !important;
    margin-left: 8px !important;
  }
  .only-margin {
    margin-right: 13px !important;
  }
  .state-main {
    padding: 0px 20px;
  }
}
@media only screen and (max-width: 390px) {
  .invoice-main {
    margin-right: 13px !important;
    margin-left: 5px !important;
  }
  .only-margin {
    margin-right: 13px !important;
  }
  .state-main {
    padding: 0px 15px;
  }
}

@media only screen and (max-width: 375px) {
  .invoice-main {
    margin-right: 0px !important;
    margin-left: 5px !important;
  }
  .only-margin {
    margin-right: 13px !important;
  }
  .state-main {
    padding: 0px 15px;
  }
}
.drop-p {
  position: relative;
}

.drop {
  position: absolute;
  cursor: pointer;
  font-size: 14px;
}
